import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'

import PageHeader from '../../components/page-header'
import Layout from '../../components/layout'
import LinkCard from '../../components/link-card'

const DownloadThanksPage = ({ data }) => {

  const { uid, data: thanksPage } = _.get(data, [
    'allPrismicThanksPage',
    'edges',
    '0',
    'node',
  ])

  const links = data?.allPrismicRecentLinks.edges[0].node.data

  return (
    <React.Fragment>
      <Layout>
        <div className="thanks">
            <section className="s-container t-hero download-thanks">
              <div className="header--desktop">
                <PageHeader
                  lede={thanksPage.heading.text}
                  subheading={thanksPage.sub_heading.text}
                />
              </div>
              <div className="header--mobile">
                <PageHeader
                  heading="Resources"
                  lede={thanksPage.heading.text}
                  subheading={thanksPage.sub_heading.text}
                />
              </div>
              <a className="link--outline" href={thanksPage.download_link.url} target="_blank" rel="noopener noreferrer" download>Download the guide</a>
            </section>
          <section className="s-container s-trendingArticles download-thanks">
            <p className="articles-lede">Trending Articles</p>
            <div className="cards grid grid--3col">
              {links.links.map((link, index) => (
                <LinkCard key={index} card={link} />
              ))}
            </div>
          </section>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default DownloadThanksPage

export const query = graphql`
    query($uid: String, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicThanksPage(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          uid
          data {
            download_link {
              url
            }
            heading {
              text
            }
            sub_heading {
              text
            }
          }
        }
      }
    }
    allPrismicRecentLinks(filter: { lang: { eq: $language } }) {
      edges {
        node {
          data {
            links {
              description
              source
              url
              photo {
                url
                thumbnails {
                  link_300_1x {
                    url
                  }
                  link_300_2x {
                    url
                  }
                  link_500_1x {
                    url
                  }
                  link_500_2x {
                    url
                  }
                  link_700_1x {
                    url
                  }
                  link_700_2x {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`



